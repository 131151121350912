<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('editMatch')}}</h5>
    </div>
    <hr>
    <b-form class="row" @submit.prevent="save()">
      <b-table striped bordered hover :items="players" :fields="columns">
        <div slot="order" slot-scope="row" class="text-right">{{ row.index + 1 }}</div>
        <div slot="participated" slot-scope="row">
          <b-form-checkbox v-model="row.item.participated" name="check-button" switch></b-form-checkbox>
        </div>
        <div slot="actions" slot-scope="row">
          <b-button variant="primary" :disabled="!row.item.participated" @click="openModalStatistics(row.item)"><i class="fa fa-plus"></i></b-button>
        </div>
        <div slot="position" slot-scope="row">
          <select :disabled="!row.item.participated" name="position" id="position" class="form-control" v-model="row.item.position">
            <option v-for="pos in positions" :key="pos.value" :value="pos.value">{{ pos.text }}</option>
          </select>
        </div>
      </b-table>
      <b-button variant="primary" type="submit">{{ $t('save') }}</b-button>
      <b-button class="ml-3" @click="$router.go(-1)">{{ $t('cancel') }}</b-button>
    </b-form>
    <modal-player-statistics :player="player" @updateStatistics="updateStatistics" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalPlayerStatistics from './ModalAddStatistics'

export default {
  components: {
    ModalPlayerStatistics
  },
  data () {
    return {
      fixtureId: this.$route.params.fixtureId,
      teamId: this.$route.params.teamId,
      players: [],
      player: {},
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'nick', label: 'Nick', sortable: false },
        { key: 'participated', label: this.$t('participated'), sortable: false },
        { key: 'position', label: this.$t('position'), sortable: false },
        { key: 'actions', label: '' }
      ],
      positions: window.positions
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'user',
      'console'
    ])
  },
  mounted() {
    document.title = `${this.$t('editMatch')} - ITFA`
    this.fetchData()
  },
  methods: {
    updateStatistics (params) {
      this.players = this.players.map(p => {
        if (p.id === params.id) {
          p.goals = params.goals
          p.assistances = params.assistances
          p.yellows = params.yellows
          p.reds = params.reds
          p.bests = params.bests
          p.played_games = params.played_games
          p.goals_against = params.goals_against
        }
        return p
      })
    },
    openModalStatistics (player) {
      this.player = JSON.parse(JSON.stringify(player))
      this.$root.$emit('bv::show::modal', 'modalStatisticsPlayer')
    },
    save () {
      const payload = {
        players: this.players.filter(p => {
          return p.participated
        })
      }
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/${this.teamId}/store-players`
      this.$axios.post(path, payload).then(() => {
        this.$router.push({ name: 'TeamBackResultsHome', params: { lang: this.lang, console: this.console } })
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/${this.teamId}/show-players`
      this.$axios.get(path).then((response) => {
        this.players = response.data.data.map(p => {
          p.participated = false
          p.goals = 0
          p.assistances = 0
          p.yellows = 0
          p.reds = 0
          p.bests = 0
          p.played_games = 0
          p.goals_against = 0
          return p
        })
      })
    }
  }
}
</script>
